.react-calendar { 
    width: 500px;
    max-width: 100%;
    background: rgba(255, 255, 255, 0.21);
    color: white;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Manrope;
    line-height: 1.125em;
    border-radius: 46.88px;

   }
   .react-calendar__navigation button {
    color: rgba(255, 255, 255, 1);
    min-width: 44px;
    background: none;
    font-size: 22px;
    margin-top: 8px;
   }
   .react-calendar__month-view{
    padding-top:20px;
    margin:10px;
   }
   .react-calendar__navigation{
    padding-top: 20px;
   }
   .react-calendar__tile .react-calendar__month-view__days__day{
    font-size: 18px;
   }
   .react-calendar__month-view__weekdays{
    font-size: 18px;
    font-weight: 400;
   }

   .react-calendar__month-view__days button {
    margin-bottom:10px;
    margin-top:20px;
   }
   .react-calendar__month-view__days{
    font-size: 20px;
    font-weight: 400;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color:transparent;
   }
   .react-calendar__navigation button[disabled] {
    background-color:transparent;
   }
   abbr[title] {
    text-decoration: none;
    font-size: larger;
    color: rgba(175, 175, 175, 1);
    padding-top: 10px;

   }
  
 
   .react-calendar__tile--now {
    background:  rgba(255, 255, 255, 0.548);
    border-radius: 6px;
    font-weight: bold;
    color:  rgba(255, 255, 255, 1);;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background:  rgba(255, 255, 255, 0.616);
    border-radius: 6px;
    font-weight: bold;
    color:  rgba(255, 255, 255, 1);;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa9c;
   }
   .react-calendar__decade-view__years button{
    margin-top:10px;
    margin-bottom: 10px;
    font-size: 18px;
   }
   .react-calendar__tile--active {
    background:  rgba(255, 255, 255, 0.733);;
    border-radius: 6px;
    font-weight: bold;
    color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background:  rgba(255, 255, 255, 0.726);;
    color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fabd;
   }
   .react-calendar__tile--range {
    background: #f8f8faa1;
    color:  rgba(255, 255, 255, 1);;
    border-radius: 0;
   }
   .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background:  rgba(255, 255, 255, 1);;
    color: white;
   }
   .react-calendar__tile--rangeEnd {
    border-radius: 50PX;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.6);
    line-height: 25px;
    color: #000000;
   }

   .react-calendar__century-view__decades button{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
   }
   .react-calendar__year-view__months button{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
   }
   .react-calendar {
    box-sizing: border-box;
}

@media (min-width: 1000px) {
    .react-calendar__navigation{
      padding-top: 70px;
    }
    .appointment_text{
        font-size: 24px;
        top:30px;
        left:30px;
    }
    .react-calendar{
        height: 458px;
    }
    #contact{
        height: 540px;
    }
  }
@media (max-width: 500px) {
    .react-calendar__navigation{
      padding-top: 70px;
    }
    .appointment_text{
        font-size: 18px;
        top:30px;
        left:30px;
    }
    .react-calendar{
        width: 350px;
        height: 458px;
    }
    .style_container{
        justify-content: center;
    }
    .react-calendar__navigation button{
        font-size: 18px;
    }
    abbr[title] {
        font-size: medium;
    }
    .react-calendar__month-view__days{
        font-size: 16px;
    }
  }