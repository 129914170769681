/* Intro.css */

.bytefuel {
  background: linear-gradient(180deg, rgba(6, 24, 24, 0.8) 0%, #000000 50%, #000000 100%);
  margin-top: 10%;
}
.group {
  display: flex;          
  width: 100vw;           
  height: calc(100vh - 0vh);
  top: calc(100vh - 135vh);
  left: 0;
  position: absolute;
  overflow: hidden;
}

.r, .l {
  flex: 1;                
  height: calc(100vh - 1vh);
}
.l {
 mix-blend-mode: color-dodge;
}
.r {
  mix-blend-mode: color-dodge;
}



/* Text Style */
.bytefuel-tag {
  position: relative;
  font-family: 'Voyager Grotesque Bold';
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 2px -6px 5px #837f7f;
}

/* Second Text Style */
.bytefuel-mission {
  position: relative;
  height: 32px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 32px;
  text-align: center;
  color: white;
  text-shadow: 1px -3px 3px #837f7f;
}

.bytefuel-motto {
  position: relative;
  margin-top: 155px;
  max-width: 633px;
  color: #FFF;
  text-align: center;
  font-family: Voyager grotesque;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-transform: uppercase;
  justify-content: center;
  
}

.bytefuel-motto span {
  font-family: 'Voyager Grotesque Bold';
}

.bytefuel-intro {
  position: relative;
  margin-top: 1%;
  max-width: 626px;
  color: rgba(255, 255, 255, 0.82);
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bytefuel-chat {
  position: absolute;
  color: rgba(255, 255, 255, 0.82);
  margin-top: 40px;
  box-shadow: 4px 4px 50px rgba(255, 255, 255, 0.3);
}

.bytefuel-services-container {
  position: relative;
  margin-left: 70%;
}

.bytefuel-services {
  position: relative;
  color: #FFFFFF;
  width: 190px;
  height: 190px;
  background: url("../assets/services.svg") no-repeat;
  animation: rotate 10s linear infinite;
  background-position: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    /* Start the rotation at 0 degrees */
  }

  to {
    transform: rotate(360deg);
    /* Rotate to 360 degrees (a full circle) */
  }
}

.services-arrow {
  position: absolute;
  left: 65px;
  top: 60px;
  color: #FFFFFF;
  width: 60px;
  height: 70px;
  background: url("../assets/arrow.svg") no-repeat;
  background-position: center;
}

@media (max-width: 500px) {
  .bytefuel{
    margin-top: 50%;
  }
  .bytefuel-tag {
    font-size: 22px;
    top: -8%;
  }

  .bytefuel-mission {
    font-size: 13px;
    top: -10%;
  }

  .bytefuel-motto {
    font-size: 14px;
    margin-top: 50px;
    padding: 10px
  }

  .bytefuel-intro {
    font-size: 12px;
  }
.services_to_team_mask{
  height: 2020px;
}
  .bytefuel-chat {
    width: 153px;
    height: 33px;
    margin-left: 0px;
    margin-top: -100px;
    position: relative;
    font-size: 12px;
  }

  .bytefuel-services-container {
    margin-left: 0%;
    top: 50%;
  }

  .bytefuel-services {
    background-size: 150px;
  }

  .services-arrow {
    background-size: 20px;
  }

  .button-container{
    margin-top: 30%;
    display: flex;
    flex-direction: column;
  }

}


@media (min-width: 501px) and (max-width: 1023px) {

  .bytefuel-services-container {
    margin-left: 0%;
    top: 100%;
  }

  .bytefuel-services {
    background-size: 150px;
  }

  .services-arrow {
    background-size: 20px;
  }
  
  .button-container{
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
  }

  .bytefuel-chat {
    position: relative;
  }

}