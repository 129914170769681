@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 0px;  /* Width of the vertical scrollbar */
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.4); /* Semi-transparent white */
}


::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.7); /* Slightly less transparent white on hover */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #061818;
  /* background: linear-gradient(180deg, #061818 0%, #000 100%); */
  overflow-x: hidden;
 width: 100vw; 
}

@font-face {
  font-family: 'Voyager Grotesque Bold';
  src: url('/public/fonts/VoyagerBold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Voyager Grotesque';
  src: url('/public/fonts/Voyager Grotesque.ttf') format('truetype');
  font-weight: 700;
}