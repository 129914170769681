.dropdown-enter {
  opacity: 0;
  transform: translateY(-100%);
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
}

.dropdown-enter-active {
  opacity: 1;
  transform: translateY(0);
  max-height: 400px; /* Adjust based on your expected content size */
  visibility: visible;
  transition: opacity 300ms, transform 300ms, max-height 300ms;
}

.dropdown-exit {
  opacity: 1;
  transform: translateY(0);
  max-height: 400px; /* Adjust based on your expected content size */
}

.dropdown-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: opacity 300ms, transform 300ms, max-height 300ms;
}
