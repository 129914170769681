    @media (max-width: 500px) {
       .close_button{
        background: white;
        border: 4px solid white;
        font-size: 20px;
        color: rgb(0, 0, 0);
        cursor: pointer;
        position: absolute;
        top: -3px;
        right: 0px;
        width: 29px;
        border-radius: 5px;
       }
       .heading_text{
        font-size: medium;
       }
       iframe
           { 
            width: calc(100% - -17px);
            height: -webkit-fill-available;
            padding: 0px;
           
            margin-top: 25px;
        }
      }

      @media (min-width: 1000px) {
        .close_button{
            background: white;
            border: 4px solid white;
            font-size: 20px;
            color: rgb(0, 0, 0);
            cursor: pointer;
            position: absolute;
            top: -3px;
            right: 0px;
            width: 29px;
            border-radius: 5px;
        }
        .heading_text{
            font-size:large;
           }
           iframe
           { 
            width: calc(100% - -17px);
            height: -webkit-fill-available;
            padding: 0px;
            margin-left: -19px;
            margin-top: 25px;
        }
       }      