.whyus-title {
  margin-top: 100px;
  color: #FFF;
  font-family: Voyager grotesque;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.whyus-div {
  margin-top: 50px;
  color: #FFF;
  text-align: center;
  font-family: Voyager grotesque;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  
}

.whyus-div h3{
  margin-top: 20px; 
}

.whyus-div p {
  margin-top: 10px;
  max-width: 300px;
  color: #D9D9D9;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.m-scroll {
  margin-top: 50px;
  display: flex;
  position: relative;
  width: 100%;
  height: 60px;
  overflow: hidden;
}

.m-scroll__title {
  display: flex;
  position: relative;
  justify-content: flex-start;
  width: 100%;
  white-space: nowrap;
  transition: all 1s ease;
}

.m-scroll__title>div {
  display: flex;
  animation: scrollText 66s infinite linear;
}

.m-scroll__title h1 {
  font-size: 46px;
  font-family: Voyager Grotesque Bold;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #ffffff;
  -webkit-text-fill-color: transparent;
  transition: all 2s ease;
}

.m-scroll__title h1 .scroll {
  display: inline-flex;
  text-decoration: none;
  color: white;
}

/*div:hover {
    animation-play-state: paused;
  }
  */
@keyframes scrollText {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}
