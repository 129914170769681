
  @media (max-width: 500px) {
    .portfolio{
      margin-top: 950px;
    }
    .portfolio-title{
      font-size: 3rem;
      text-align: center;
      margin-left: 0;
    }
    .direction_down{
      flex-direction: column;
    }
    .direction_down .images img{
      max-width: 80%;
    }
  }
  @media only screen and (max-width: 800px) and (min-width: 500px) {
    .portfolio{
      margin-top: 823px;
    }

  }
  @media (min-width: 2000px) {
    .portfolio{
      margin-top: -200px;
    }
   
  }
  @media only screen and (min-width: 800px) and (max-width: 1500px) {
    .portfolio{
      margin-top: 823px;
    }

  }
